const ChairishContext = window.chairisher.context;

/**
 * @returns {Array.<Object>} Collection of indicator image srcset objects to use in the image viewer for seller reviews
 */
export function getReviewImageViewerIndicatorSrcsets() {
    return ChairishContext.REVIEW_IMAGE_VIEWER_INDICATOR_SRCSETS || [];
}

/**
 * @returns {Array.<Object>} Collection of image srcset objects to use in the image viewer for seller reviews
 */
export function getReviewImageViewerSrcsets() {
    return ChairishContext.REVIEW_IMAGE_VIEWER_SRCSETS || [];
}

/**
 * @returns {number} The number of reviews for a given seller
 */
export function getNumSellerReviews() {
    return ChairishContext.NUM_SELLER_REVIEWS;
}

/**
 * @returns {number|null} The average rating for a given seller
 */
export function getSellerRating() {
    return ChairishContext.SELLER_RATING || null;
}

/**
 * @param {number=} productId The product id to pass up to the reviews endpoint
 * @returns {string} The endpoint to get reviews for a given seller
 */
export function getSellerReviewsUrl(productId = null) {
    const url = ChairishContext.SELLER_REVIEWS_URL;
    return productId ? `${url}?product-id=${productId}` : url;
}
